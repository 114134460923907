<template>
  <div right>
    <!-- <b-button
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="danger"
      size="sm"
      pill
      :to="{ name: 'games' }"
    >
      <i
        class="fad fa-gamepad"
        style="font-size: 1rem; margin-right: 4px"
      />

      <small>เข้าเล่นเกม</small>
    </b-button> -->
    <b-button
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="outline-danger"
      size="sm"
      style="margin-top: 5px"
      pill
    >
      <a
        class="text-danger"
        href=""
      >
        <i
          class="fad fa-home"
          style="font-size: 1rem; margin-right: 4px"
        />
        <small style="font-size: 0.9rem">เข้าเว็บไซต์</small>
      </a>
    </b-button>

    <!--
    <img
      class="cursor-pointer"
      height="25px"
      src="/menu.svg"
      alt=""
      @click="showModal"
    > -->

    <b-modal
      ref="my-modal"
      hide-footer
      class="text-center color_ra"
    >
      <div class="d-block text-center">
        <h3>{{ userData.username }}</h3>
      </div>
      <div class="d-block text-center mb-4">
        <button
          class="btn_exit"
          @click="logout"
        >
          <i class="fad fa-door-open" />
          <span class="align-middle">ออกจากระบบ</span>
        </button>
      </div>
    </b-modal>

    <!-- <div class="us p-1">
        <div style=" margin-top:-7px">
          <small
            class="user-status p-1"
            style="font-size: 0.55rem;"
          >
            {{ userData.username }}
          </small>
        </div>
      </div> -->
    <!-- <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar> -->
  </div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

import axios from 'axios'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: { BModal, BButton },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      CountLogin: localStorage.getItem('CountLogin'),
      Ip: null,
      avatarText,
    }
  },
  mounted() {
    // this.CheckLogin()
  },
  methods: {
    CheckLogin() {
      this.ref = setInterval(async () => {
        await axios
          .get('https://api.ipify.org')
          .then(response => {
            this.Ip = response.data
          })
          .catch(error => console.log(error))

        const params = {
          Ip: this.Ip,
        }
        this.CountLogin = Number(localStorage.getItem('CountLogin'))
        await this.$http
          .get('https://api.ufabangkok.com/api/auth/checklogin', { params })
          .then(response => {
            if (response.data !== this.CountLogin) {
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('loglevel:webpack-dev-server')
              localStorage.removeItem('userData')
              localStorage.removeItem('token')
              localStorage.removeItem('CountLogin')
              window.location.reload()
            }
          })
        //   .catch(error => console.log(error))
      }, 60000)
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('CountLogin')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      // this.userData.agent_id
      console.log(this.userData.agent_id)
      this.$router.push(`/login?ag=${this.userData.agent_id}`)
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap');
.us {
  max-width: 580px;
  font-family: 'Orbitron', sans-serif;
  color: #ffffff;
  background-image: url(/platr1.png);
  background-position: 2%;
  background-repeat: no-repeat;
  background-size: cover;
}
.btn_exit {
  background-color: #ffc800;
  border-radius: 38px;
  border-color: transparent;
  padding: 10px 25px;
  width: 100%;
}
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}
.color_ra {
  box-shadow: 2px 2px 10px #ffc800;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #442673;
  border: 1px solid #fff;
  box-shadow: 0px 0px 15px rgb(143, 0, 131);
  border-radius: 5px;
  max-width: 540px;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}
</style>
