<template>
  <div>
    <div class="text-center neon">
      <div class="bottombar-mobile">
        <ul
          class="nav-menu container-sm"
          style="margin: auto"
        >
          <li class="nav-item">
            <router-link :to="{ name: 'dashboard-hitler' }">
              <a
                class="nav-link"
              ><img
                 src="/iconNew/icon-เล็กด้านล่าง-01.png"
                 alt="deposit"
                 height="30"
               >
                <span class="text-white">กระเป๋า</span>
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'deposit' }"
              class="nav-link"
            >
              <img
                src="/iconNew/icon-เล็กด้านล่าง-02.png"
                alt="deposit"
                height="30"
              >
              <span class="text-white">เติมเงิน</span>
            </router-link>
          </li>
          <li class="nav-item middle-item">
            <div @click="play()">
              <a
                class="nav-link"
              >
                <img
                  src="/iconNew/play.svg"
                  alt="play"
                  height="35"
                  style="margin-left: 5px;"
                >
              </a>
              <div><span class="text-white">เข้าเกมส์</span></div>
            </div>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'withdraw' }"
              class="nav-link"
            >
              <img
                src="/iconNew/icon-เล็กด้านล่าง-03.png"
                alt="deposit"
                height="30"
              >
              <span class="text-white">ถอนเงิน</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'profile' }"
              class="nav-link"
            >
              <img
                src="/iconNew/icon-เล็กด้านล่าง-04.png"
                alt="deposit"
                height="30"
              >
              <span class="text-white">โปรไฟล์</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    play() {
      const formData = {
        username: this.userData.username,
        password: `Ufa${this.userData.tel}`,
      }
      this.$http
        .post('https://api.ufabangkok.com/api/play/login', formData)
        .then(response => {
          const { status } = response.data
          if (status === 'error') {
            this.SwalError(
              'UFA ปิดปรับปรุงเว็บไซต์ชั่วคราว ขออภัยในความไม่สะดวก',
            )
          } else {
            window.location.href = response.data.gameUrl
          }
        })
        .catch(error => console.log(error))
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
.fa-gamepad path {
  stroke: rgb(0, 0, 0);
  stroke-width: 30px;
}
.container-sm {
  max-width: 540px;
}
.neon {
  /* font-family: 'Helvethaica', sans-serif; */
  background: #0b0e11;
  padding: 10px 10px 0px 5px;
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.bf {
  background-image: url(/iconJK/game.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
span {
  font-size: 18px;
}
</style>
```
